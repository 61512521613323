<template>
	<div>
		<div class="vx-row">
			<div class="vx-col lg:w-1/2 w-full">
				<vx-card>
					<div class="vx-row">
						<div class="vx-col w-full mb-2">
							<label class="vs-input--label">Email*</label>
							<vs-input class="w-full" v-model="formData['email']"/>
						</div>
					</div>
					<div class="vx-row">
						<div class="vx-col w-full mt-4">
							<vs-button ref="loadableButton" id="button-one-with-loading" class="mb-2" @click="openEmailConfirm()" :disabled="user_role != 'admin' || formData['email'] == ''">Submit</vs-button>
						</div>
					</div>
				</vx-card>
			</div>
			<div class="vx-col lg:w-1/2 w-full">
				<vx-card>
					<div class="vx-row">
						<div class="vx-col w-full mb-2">
							<label class="vs-input--label">Phone*</label>
							<vs-input class="w-full" v-model="formData['phone']"/>
						</div>
					</div>
					<div class="vx-row">
						<div class="vx-col w-full mt-4">
							<vs-button ref="loadableButton" id="button-two-with-loading" class="mb-2" @click="openPhoneConfirm()" :disabled="user_role != 'admin' || formData['phone'] == ''">Submit</vs-button>
						</div>
					</div>
				</vx-card>
			</div>
		</div>
	</div>
</template>

<script>
	import vSelect from 'vue-select'

	export default{
		components: {
			'v-select': vSelect,
		},
		data() {
			return {
				formData: {
					email: '',
					phone: '',
				},
			}
		},
		watch: {
			
		},
		computed: {
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showButtonOneLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-one-with-loading',
					scale: 0.45
				});
			},
			hideButtonOneLoading(){
				this.$vs.loading.close("#button-one-with-loading > .con-vs-loading");
			},
			showButtonTwoLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-two-with-loading',
					scale: 0.45
				});
			},
			hideButtonTwoLoading(){
				this.$vs.loading.close("#button-two-with-loading > .con-vs-loading");
			},
			openEmailConfirm() {
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Add this Blacklist Registrant Email?',
					accept: this.addBlacklistRegistrantEmail
				});
			},
			openPhoneConfirm() {
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Add this Blacklist Registrant Phone?',
					accept: this.addBlacklistRegistrantPhone
				});
			},
			addBlacklistRegistrantEmail() {
				let self = this;
				// Show loader
				self.showButtonOneLoading();
				// Post Axios Call
				this.$axiosSecure.post('/save-blacklisted-registrant-email', {
					email: self.formData['email'],
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then((success) => {
					if(success.data == "created") {
						// Confimation notification
						self.$vs.notify({
							color: 'success',
							title: 'Blacklist registrant save success',
							text: 'The Blacklist registrant email has been added'
						});
						
						// Hide loader
						self.hideButtonOneLoading();
						self.$router.push({ path: '/blacklist-registrant-list' });
					}
					else {
						// Confimation notification
						self.$vs.notify({
							color: 'warning',
							title: 'Blacklist registrant save error',
							text: 'The Blacklist registrant email is a duplicate'
						});
						
						// Hide loader
						self.hideButtonOneLoading();
					}
				})
				.catch((error) => {
					// Hide loader
					self.hideButtonOneLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			addBlacklistRegistrantPhone() {
				let self = this;
				// Show loader
				self.showButtonTwoLoading();
				// Post Axios Call
				this.$axiosSecure.post('/save-blacklisted-registrant-telephone', {
					telephone: self.formData['phone'],
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then((success) => {
					if(success.data == "created") {
						// Confimation notification
						self.$vs.notify({
							color: 'success',
							title: 'Blacklist registrant save success',
							text: 'The Blacklist registrant phone has been added'
						});
						
						// Hide loader
						self.hideButtonTwoLoading();
						self.$router.push({ path: '/blacklist-registrant-list' });
					}
					else {
						// Confimation notification
						self.$vs.notify({
							color: 'warning',
							title: 'Blacklist registrant save error',
							text: 'The Blacklist registrant phone is a duplicate'
						});
						
						// Hide loader
						self.hideButtonTwoLoading();
					}
				})
				.catch((error) => {
					// Hide loader
					self.hideButtonTwoLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
		},
		mounted() {
			
		}
	}
</script>
